<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
        md="12"
        lg="12"
      >
        <v-tabs
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-card
                  :title="form.aktion == 'editEventCheck' ? 'Event bearbeiten' : 'Event erstellen'"
                >
                  <v-card-title v-if="form.aktion == 'editEventCheck'">Event bearbeiten</v-card-title>
                  <v-card-title v-else>Event erstellen</v-card-title>

                  <v-card-text>

                    <div class="text-center" v-if="loading">
                      <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                    <v-form
                      v-else
                      ref="formRef"
                      @submit.prevent="saveData"
                    >
                      <v-row>
                        <v-col cols="12" md="8">
                          <v-text-field
                            v-model="form.mainData.titel"
                            label="Titel"
                            dense
                            hide-details
                            outlined
                            :rules="[titleRules]"
                          ></v-text-field>
                        </v-col>
                        <v-col md="4">
                          <v-select
                            class="mb-3"
                            v-model="form.mainData.angabeAktiv"
                            hide-details
                            outlined
                            dense
                            :items="[{value:1,text:'Ja'},{value:0,text:'Nein'}]"
                            item-text="text"
                            item-value="value"
                            label="Aktiv"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12" class="pt-0">
                          <v-textarea
                            v-model="form.mainData.memo"
                            label="Memo"
                            auto-grow
                            dense
                            hide-details
                            outlined
                            rows="6"
                            row-height="15"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <v-radio-group
                            v-model="form.mainData.eventTyp"
                            column
                            class="mt-0"
                          >
                            <v-radio value="http" label="HTTP"></v-radio>
                            <v-radio value="cpc" label="CPC-Protokollierung"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <v-row v-if="form.mainData.eventTyp == 'http'">
                        <v-col cols="12">
                          <v-text-field
                            v-model="form.mainData.url"
                            label="URL"
                            placeholder="https://"
                            dense
                            hide-details
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row v-if="form.mainData.eventTyp == 'cpc'">
                        <v-col cols="12" md="4">
                          <v-select
                            class=""
                            v-model="form.mainData.waehrung"
                            hide-details
                            outlined
                            dense
                            :items="[{value:'EUR'}]"
                            item-text="value"
                            item-value="value"
                            label="Währung"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="8">
                          <v-text-field
                            v-model="form.mainData.cpc"
                            label="CPC"
                            placeholder="cpc"
                            dense
                            hide-details
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12">
                          <router-link :to="'/event'">
                            <v-btn
                              color="error"
                              class="mx-2"
                            >
                              Zurück
                            </v-btn>
                          </router-link>
                          <v-btn color="primary" type="submit" :disabled="editValidationError">
                            Speichern
                          </v-btn>
                          <v-btn
                            type="reset"
                            outlined
                            class="mx-2"
                          >
                            Zurücksetzen
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>

                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {onMounted, ref, getCurrentInstance} from '@vue/composition-api'
import router from '@/router'
import {
  mdiHomeOutline,
  mdiEmailOutline,
  mdiLockOutline,
  mdiEyeOffOutline,
  mdiEyeOutline
} from '@mdi/js'

import axios from "axios";
import {emailValidator, required} from "@core/utils/validation";

export default {
  components: {

  },
  setup(props) {

    const userTab = ref(null)
    const tabs = ref([
      { icon: mdiHomeOutline, title: 'Übersicht', id:'overview' },
    ])

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 2000;

    const formRef = ref(null)
    const form = ref({})
    const showMemo = ref(false)
    const editValidationError = ref(false)

    form.value = {
      mainData : {
        idNipEvent : router.currentRoute.params.idNipEvent,
        angabeAktiv: 1,
        titel: '',
        memo: '',
        eventTyp: 'http',
        url: '',
        waehrung: 'EUR',
        cpc: 0.0,
      },
      aktion: (router.currentRoute.params.idNipEvent !== undefined ? 'editEventCheck' : 'createEventCheck'),
    };
    const loading = ref(true)

    onMounted(()=>{

      if(form.value.aktion == 'editEventCheck'){
        axios.post('/api/event/', {
          aktion: 'editEvent',
          mainData:{
            idNipEvent : router.currentRoute.params.idNipEvent,
          }
        })
          .then((response) => {
            if (response.data.valid === true) {
              form.value.mainData = response.data.event;
              showMemo.value = form.value.mainData.memo.length > 0

            } else {
              response.data.errors.forEach(item=>{
                let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
                vm.$toastr.e(item.errorText, item.errorTitle+suffix);
              })
            }
            loading.value = false;
          })
          .catch((error) => {
            console.log(error)
            loading.value = true;
          })
      }
      else{
        loading.value = false;
      }
    })

    const saveData = () => {
      const isFormValid = formRef.value.validate()
      if(!isFormValid) return
      loading.value = true;

      const fd = new FormData();
      fd.append('aktion',form.value.aktion);
      fd.append('mainData[idNipEvent]',form.value.mainData.idNipEvent);
      fd.append('mainData[angabeAktiv]',form.value.mainData.angabeAktiv);
      fd.append('mainData[titel]',form.value.mainData.titel);
      fd.append('mainData[memo]',form.value.mainData.memo);
      fd.append('mainData[eventTyp]',form.value.mainData.eventTyp);
      fd.append('mainData[url]',form.value.mainData.url);
      fd.append('mainData[waehrung]',form.value.mainData.waehrung);
      fd.append('mainData[cpc]',form.value.mainData.cpc);

      axios
        .post('/api/event/',fd)
        .then(response => {
          if(response.data.valid === true){
            router.push('/event',()=>{
              vm.$toastr.s(response.data.message.text, response.data.message.title);
            })
          }
          else{
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
          loading.value = false;
        })
        .catch(error => {
          vm.$toastr.e(error,error);
          loading.value = false;
        })
    }

    const titleRules = (v) => {
      editValidationError.value = true
      if(!v) {
        return 'Bitte füllen Sie dieses Feld aus';
      }
      else if((v || '').length < 2) {
        return 'Der Titel muss mindetens 2 Zeichen besitzen.';
      }
      else if((v || '').length > 45) {
        return 'Der Titel kann maximal 45 Zeichen lang sein.';
      }

      editValidationError.value = false
      return true
    }

    return {
      userTab,
      tabs,
      form,
      formRef,
      saveData,
      loading,
      showMemo,
      editValidationError,
      titleRules,
      icons: {
        mdiHomeOutline,
        mdiEmailOutline,
        mdiLockOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      validators: {
        required,
        emailValidator,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.user-tabs{
  display: none;
}
#user-tabs-content{
  margin:0 !important;
}
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
.v-input__slot {
  margin-bottom: 0px;
}

</style>
